import React from 'react';

const List = ({
  items,
  mobileVerticalScroll,
}) => {
  return (
    <div className={`_list-wrapper ${mobileVerticalScroll ? '_list-vertical-scroll' : ''}`}>
      {
        items?.map((item, index) => (
          <div key={index} className={`_list-item`}>
            {item}
          </div>
        ))
      }
    </div>
  )
};

export default List;
