import React from 'react';
import { useTranslation } from 'react-i18next';

import useDeviceDetect from '../../utils/useDeviceDetect';
import Button from '../Button';
import quotesIllustration from '../../images/illustrations/quote.svg';
import linkdinWhiteIcon from '../../images/icons/linkedinWhite.svg';
import mailWhiteIcon from '../../images/icons/mailWhite.svg';

const Card = ({
  id,
  title,
  description,
  actionButton,
  image,
  imageAlt,
  type,
  actionButtonLink,
  linkedin,
  email,
}) => {
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();

  const displayActionCard = () => (
    <div className={`_card-wrapper`} id={id}>
      <img src={image} alt={imageAlt} className={`_card-image`} />
      <div className={`_card-body`}>
        <div className={
          `${isMobile ? 'text-mobile-h2' : 'text-web-h3'} _card-body-title`
        }>
          {title}
        </div>
        <div className={
          `text-mobile-paragraph-small _card-body-description`
        }>
          {description}
        </div>
        <div className={`_card-body-actionButton`}>
          <Button filled={true} to={actionButtonLink}>{actionButton}</Button>
        </div>
      </div>
    </div>
  );

  const displayReviewCard = () => (
    <div className={`_card-wrapper`}>
      <div className={`_card-quotes`}>
        <img src={quotesIllustration} alt={t('imagesAlt.quotes')} />
      </div>
      <div className={`_card-body`}>
        <div className={
          `${isMobile ? 'text-mobile-h2' : 'text-web-h3'} _card-body-title _card-body-title-quotes` 
        }>
          {title}
        </div>
        <div className={
          `text-mobile-paragraph-small _card-body-description _card-no-margin`
        }>
          {description}
        </div>
      </div>
    </div>
  );

  const displayPersonCard = () => (
    <div className={`_card-wrapper-person`}>
      <div className={`_card-body-person`}>
        <img src={image} alt={imageAlt} className={`_card-image-person`} />
        <div className={`_card-body-info-person`}>
          <div className={
            `${isMobile ? 'text-mobile-h6' : 'text-web-strong'}` 
          }>
            {title}
          </div>
          <div className={
            `${isMobile ? 'text-mobile-paragraph-small' : 'text-web-label-input'}`
          }>
            {description}
          </div>
          <div className={`_card-links-person`}>
            <Button
              isOnlyIcon={true}
              filled={true}
              iconLeft={linkdinWhiteIcon}
              iconLeftAlt={t('imagesAlt.linkedin')}
              to={linkedin}
              isExternalLink={true}
            />
            <Button
              isOnlyIcon={true}
              filled={true}
              iconLeft={mailWhiteIcon}
              iconLeftAlt={t('imagesAlt.mail')}
              isExternalLink={true}
              to={`mailto:${email}`}
            />
          </div>
        </div>
      </div>
    </div>
  );

  switch(type) {
    case 'actionCard':
      return displayActionCard();
    case 'reviewCard':
      return displayReviewCard();
    case 'personCard':
      return displayPersonCard();
    default:
      return null;
  }
}

export default Card;
