import React from 'react';
import useDeviceDetect from '../../utils/useDeviceDetect';

import Spacer from '../Spacer';

const ColumnDescriptions = ({
  items = [],
}) => {
  const { isMobile } = useDeviceDetect();

  return (
    <div className={`_columnDescriptions-wrapper`}>
      {
        items.map((item, index) => (
          <div key={item.title} className={`_columnDescriptions-item-wrapper`}>
            {
              item.image && (
                <img className={`_columnDescriptions-item-image`} src={item.image} alt={item.imageAlt} />
              )
            }
            <div className={`${ isMobile ? 'text-mobile-h3' : 'text-web-h3'} _columnDescriptions-item-title`}>{item.title}</div>
            <div className={`${ isMobile ? 'text-mobile-paragraph-small' : 'text-web-paragraph-medium'} _columnDescriptions-item-description`}>{item.description}</div>
            {
              items.length > index + 1 && (
                <Spacer />
              )
            }
          </div>
        ))
      }
    </div>
  )
};

export default ColumnDescriptions;
