import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import "@fontsource/montserrat";
import '../styles/index.scss';

import Navbar from '../components/Navbar';
import PageHeader from '../components/PageHeader';
import Section from "../components/Section";
import SectionTitle from "../components/SectionTitle";
import Card from '../components/Card';
import SupportLogos from "../components/SupportLogos";
import List from "../components/List";
import ColumnDescriptions from "../components/ColumnDescriptions";
import ContactSection from '../components/ContactSection';

import how1Illustration from '../images/illustrations/how1.svg';
import how2Illustration from '../images/illustrations/how2.svg';
import how3Illustration from '../images/illustrations/how3.svg';
import mitLogo from '../images/pictures/mitLogo.svg';
import cideLogo from '../images/pictures/cideLogo.png';
import innovateLogo from '../images/pictures/innovateLogo.svg';
import serviceIndustry from '../images/photos/web/serviceIndustry.png';
import serviceNoIndustry from '../images/photos/web/serviceNoIndustry.png';
import Footer from "../components/Footer";
import getMetadata from '../utils/getMetadata';
import FloatButton from "../components/FloatButton";
import mailWhiteIcon from '../images/icons/mailWhite.svg'; 
import wppWhiteIcon from '../images/icons/wppWhite.svg'; 

const Home = ({ pageContext, location }) => {
  const { lang } = pageContext;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [])

  return (
    <>
      {
        getMetadata({
          pageName: 'home',
          link: '',
          lang,
          t,
        })
      }
      <Navbar location={location} />
      <FloatButton
        image={mailWhiteIcon}
        imageAlt={t('floatButton.contact.imageAlt')}
        link={`/${lang}/contact`}
        index={0}
      />
      <FloatButton
        image={wppWhiteIcon}
        imageAlt={t('floatButton.wpp.imageAlt')}
        link={`https://wa.me/51998785550?text=${encodeURIComponent(t('floatButton.wpp.text'))}`}
        index={1}
        isExternalLink={true}
      />
      <PageHeader
        title={t(`home.header.title`)}
        description={t(`home.header.description`)}
        buttons={[
          {
            title: t(`home.header.actionButton`),
            href: `/${lang}/services`
          }
        ]}
        photo='home'
        photoAlt={t('home.header.imageAlt')}
      />
      <Section
        title={t('home.sections.how.title')}
        isTwoColumns={true}
        isGray={true}
        items={[
          (
            <SectionTitle
              title={t('home.sections.how.subTitle')}
            />
          ),
          (
            <ColumnDescriptions
              items={[
                {
                  title: t('home.sections.how.step1.title'),
                  description: t('home.sections.how.step1.description'),
                  image: how1Illustration,
                  imageAlt: t('home.sections.how.step1.imageAlt'),
                },
                {
                  title: t('home.sections.how.step2.title'),
                  description: t('home.sections.how.step2.description'),
                  image: how2Illustration,
                  imageAlt: t('home.sections.how.step2.imageAlt'),
                },
                {
                  title: t('home.sections.how.step3.title'),
                  description: t('home.sections.how.step3.description'),
                  image: how3Illustration,
                  imageAlt: t('home.sections.how.step3.imageAlt'),
                },
              ]}
            />
          )
        ]}
      />
      <Section
        title={t('home.sections.support.title')}
        items={[
          (
            <SectionTitle
              title={t('home.sections.support.subTitle')}
              description={t('home.sections.support.description')}
              bullets={true}
            />
          ),
          (
            <SupportLogos
              logos={[
                {
                  logo: innovateLogo,
                  logoAlt: t('home.sections.support.innovateLogoAlt')
                }, {
                  logo: mitLogo,
                  logoAlt: t('home.sections.support.mitLogoAlt')
                },
                {
                  logo: cideLogo,
                  logoAlt: t('home.sections.support.cideLogoAlt')
                }
              ]}
            />
          )
        ]}
      />
      <Section
        title={t('home.sections.services.title')}
        isTwoColumns={true}
        isGray={true}
        items={[
          (
            <SectionTitle
              title={t('home.sections.services.subTitle')}
              description={t('home.sections.services.description')}
              actionButton={t('home.sections.services.actionButton')}
              actionButtonLink={`/${lang}/services`}
            />
          ),
          (
            <List
              items={[
                (
                  <Card
                    title={t('home.sections.services.industry.title')}
                    description={t('home.sections.services.industry.description')}
                    actionButton={t('home.sections.services.industry.actionButton')}
                    actionButtonLink={`/${lang}/contact`}
                    image={serviceIndustry}
                    imageAlt={t('home.sections.services.industry.title')}
                    type='actionCard'
                  />
                ),
                (
                  <Card
                    title={t('home.sections.services.noIndustry.title')}
                    description={t('home.sections.services.noIndustry.description')}
                    actionButton={t('home.sections.services.noIndustry.actionButton')}
                    actionButtonLink={`/${lang}/contact`}
                    image={serviceNoIndustry}
                    imageAlt={t('home.sections.services.noIndustry.title')}
                    type='actionCard'
                  />
                ),
              ]}
            />
          )
        ]}
      />
      {/* <Section
        title={t('home.sections.succeedCases.title')}
        items={[
          (
            <SectionTitle
              title={t('home.sections.succeedCases.subTitle')}
            />
          ),
          (
            <List
              items={[
                (
                  <Card
                    title={t('succeedCases.sections.succeedCases.case1.title')}
                    description={t('succeedCases.sections.succeedCases.case1.description')}
                    actionButton={t('succeedCases.sections.succeedCases.case1.actionButton')}
                    image={serviceIndustry}
                    type='actionCard'
                  />
                ),
                (
                  <Card
                    title={t('succeedCases.sections.succeedCases.case1.title')}
                    description={t('succeedCases.sections.succeedCases.case1.description')}
                    actionButton={t('succeedCases.sections.succeedCases.case1.actionButton')}
                    image={serviceNoIndustry}
                    type='actionCard'
                  />
                ),
                (
                  <Card
                    title={t('succeedCases.sections.succeedCases.case1.title')}
                    description={t('succeedCases.sections.succeedCases.case1.description')}
                    actionButton={t('succeedCases.sections.succeedCases.case1.actionButton')}
                    image={serviceIndustry}
                    type='actionCard'
                  />
                ),
              ]}
            />
          )
        ]}
      /> */}
      {/* <Section
        title={t('home.sections.reviews.title')}
        isTwoColumns={true}
        items={[
          (
            <SectionTitle
              title={t('home.sections.reviews.subTitle')}
            />
          ),
          (
            <List
              mobileVerticalScroll={true}
              items={[
                (
                  <Card
                    title={t('home.sections.reviews.review1.title')}
                    description={t('home.sections.reviews.review1.description')}
                    type='reviewCard'
                  />
                ),
                (
                  <Card
                    title={t('home.sections.reviews.review2.title')}
                    description={t('home.sections.reviews.review2.description')}
                    type='reviewCard'
                  />
                ),
              ]}
            />
          ),
        ]}
      /> */}
      <ContactSection
        mobileMainTitle={t('home.sections.contact.title')}
        title={t('home.sections.contact.subTitle')}
        description={t('home.sections.contact.description')}
        isGray={true}
      />
      <Footer lang={lang}/>
    </>
  );
};

export default Home;
